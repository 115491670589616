import { buildUrl, buildUrlWithParameters, deleteRequest, get, patch, post, put } from "../utils/axiosRequests";
import {
  AGACAL_API_PLAGUES,
  AGACAL_API_PLOTS,
  AGACAL_API_PLOT,
  AGACAL_API_WEATHER_GRAPH,
  AGACAL_API_WEATHER_GRAPH_LAST_UPDATE,
  DEFAULT_HTTP_TIMEOUT,
  AGACAL_API_PLOTS_BY_PLAGUE,
  AGACAL_API_CELLARS,
  AGACAL_API_TREATMENTS,
  AGACAL_API_ZONES,
  AGACAL_API_VARIETIES_GRAPES,
  AGACAL_API_PLOT_FEN_STATES,
  AGACAL_API_METEO_SUMMARY,
  AGACAL_API_PLAGUE_RISKS,
  AGACAL_API_WARNINGS,
  AGACAL_API_WARNING,
  AGACAL_API_PHYTOSANITARY_PRODUCTS,
  AGACAL_API_PROVINCES,
  AGACAL_API_COUNCILS,
  AGACAL_API_STATIONS,
  AGACAL_API_USERS,
  AGACAL_API_USER_PLOTS,
  AGACAL_API_USER_CELLARS,
  AGACAL_API_EDIT_CELLARS,
  AGACAL_API_TREATMENTS_CREATE,
  AGACAL_API_USER,
  AGACAL_API_USER_CHANGE_PASS,
  AGACAL_API_FEN_STATES,
  AGACAL_API_PLOT_FEN_STATE,
  AGACAL_API_TREATMENTS_DELETE,
  AGACAL_API_USERS_PUBLIC,
  AGACAL_API_PLAGUES_PUBLIC,
  AGACAL_API_PLAGUE_RISKS_PUBLIC,
  AGACAL_API_WARNING_TRANSLATIONS, AGACAL_API_PLOT_FEN_STATE_DELETE,
} from "../utils/constants";

export const getWeatherGraphService = (plot_id, parameters, callback, loading = true) => {
  let url = buildUrl(AGACAL_API_WEATHER_GRAPH, { plot_id });
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, loading, callback);
};

export const getWeatherGraphLastUpdateService = (plot_id, callback, loading = true) => {
  let url = buildUrl(AGACAL_API_WEATHER_GRAPH_LAST_UPDATE, { plot_id });
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, loading, callback);
};

export const getFenStateService = (plot_id, parameters, callback) => {
  let url = buildUrl(AGACAL_API_PLOT_FEN_STATES, { plot_id });
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getPlotDataService = (plot_id, successCallback, loading = false) => {
  const url = buildUrl(AGACAL_API_PLOT, { plot_id });
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, loading, successCallback);
};

export const getPlotMeteoSummaryService = (plot_id, parameters, successCallback) => {
  let url = buildUrl(AGACAL_API_METEO_SUMMARY, { plot_id });
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, successCallback);
};

export const getPlotPlagueRisksService = (plot_id, parameters, successCallback) => {
  let url = buildUrl(AGACAL_API_PLAGUE_RISKS, { plot_id });
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, successCallback);
};

export const getPlaguesService = (parameters, successCallback) => {
  const url = buildUrlWithParameters(AGACAL_API_PLAGUES, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, successCallback);
};

export const getPublicPlaguesService = (parameters, successCallback) => {
  const url = buildUrlWithParameters(AGACAL_API_PLAGUES_PUBLIC, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, successCallback);
};

export const getPublicPlagueRisks = (parameters, callback) => {
  const url = buildUrlWithParameters(AGACAL_API_PLAGUE_RISKS_PUBLIC, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, true, callback);
};

export const getPlotsService = (parameters = {}, callback) => {
  let url = buildUrl(AGACAL_API_PLOTS, {});
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getPlotsByPlagueService = (parameters, callback) => {
  let url = buildUrl(AGACAL_API_PLOTS_BY_PLAGUE, {});
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getTreatmentsService = (plot_id, parameters = {}, callback) => {
  let url = buildUrl(AGACAL_API_TREATMENTS, { plot_id });
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const deleteTreatmentService = (treatment_id, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_TREATMENTS_DELETE, { treatment_id });
  deleteRequest(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const postCreateTreatmentService = (body, callback) => {
  const url = buildUrl(AGACAL_API_TREATMENTS_CREATE, {});
  post(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, true, callback);
};

export const getCellarsService = (parameters = {}, callback) => {
  let url = buildUrl(AGACAL_API_CELLARS, {});
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const postCreateCellarService = (body, callback) => {
  const url = buildUrl(AGACAL_API_CELLARS, {});
  post(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, true, callback);
};

export const deleteCellarService = (cellar_id, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_EDIT_CELLARS, { cellar_id });
  deleteRequest(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const patchCellarService = (cellar_id, data, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_EDIT_CELLARS, { cellar_id });
  patch(url, data, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const getZonesService = (callback) => {
  const url = buildUrl(AGACAL_API_ZONES, {});
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getVarietiesGrapesService = (callback) => {
  const url = buildUrl(AGACAL_API_VARIETIES_GRAPES, {});
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getWarningsService = (parameters = {}, callback, errorCallback = undefined, headers = {}) => {
  let url = buildUrl(AGACAL_API_WARNINGS, {});
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, errorCallback, headers);
};

export const deleteWarningService = (warning_id, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_WARNING, { warning_id });
  deleteRequest(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const patchWarningService = (warning_id, data, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_WARNING, { warning_id });
  patch(url, data, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const postCreateWarningService = (body, callback, errorCallback) => {
  const url = buildUrl(AGACAL_API_WARNINGS, {});
  post(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, true, callback, errorCallback);
};

export const postEditTranslationsWarningService = (warning_id, body, callback, errorCallback) => {
  const url = buildUrl(AGACAL_API_WARNING_TRANSLATIONS, { warning_id });
  post(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, errorCallback);
};

export const getPhytosanitaryProductsService = (parameters = {}, callback) => {
  let url = buildUrl(AGACAL_API_PHYTOSANITARY_PRODUCTS, {});
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const postCreatePlotService = (body, callback, errorCallback) => {
  const url = buildUrl(AGACAL_API_PLOTS, {});
  post(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, true, callback, errorCallback);
};

export const deletePlotService = (plot_id, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_PLOT, { plot_id });
  deleteRequest(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const getProvincesService = (callback) => {
  const url = buildUrl(AGACAL_API_PROVINCES, {});
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getCouncilsService = (callback, region) => {
  let url = buildUrl(AGACAL_API_COUNCILS, {});

  if (region) {
    url = `${url}?region=${region}`;
  }

  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getStationsService = (parameters, callback) => {
  let url = buildUrl(AGACAL_API_STATIONS, {});
  url = buildUrlWithParameters(url, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getUsersService = (parameters, callback) => {
  const url = buildUrlWithParameters(AGACAL_API_USERS, parameters);
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const postCreateUserService = (body, callback, errorCallback) => {
  const url = buildUrl(AGACAL_API_USERS, {});
  post(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, errorCallback);
};

export const deleteUserService = (user_id, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_USER, { user_id });
  deleteRequest(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const updateUserPlotsService = (user_id, body, callback) => {
  const url = buildUrl(AGACAL_API_USER_PLOTS, { user_id });
  put(url, body, {}, false, callback);
};

export const updateUserCellarsService = (user_id, body, callback) => {
  const url = buildUrl(AGACAL_API_USER_CELLARS, { user_id });
  put(url, body, {}, false, callback);
};

export const updateUserRoleService = (user_id, body, callback, catchCallback) => {
  const url = buildUrl(AGACAL_API_USER, { user_id });
  patch(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const updateUserPassService = (parameters, callback, catchCallback) => {
  const url = buildUrlWithParameters(AGACAL_API_USER_CHANGE_PASS, parameters);
  post(url, {}, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, catchCallback);
};

export const getUserPlotsService = (user_id, callback) => {
  const url = buildUrl(AGACAL_API_USER_PLOTS, { user_id });
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getUserCellarsService = (user_id, callback) => {
  const url = buildUrl(AGACAL_API_USER_CELLARS, { user_id });
  get(url, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const getFenStatesService = (callback) => {
  get(AGACAL_API_FEN_STATES, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback);
};

export const updatePlotService = (plot_id, body, callback, errorCallback) => {
  const url = buildUrl(AGACAL_API_PLOT, { plot_id });
  patch(url, body, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, errorCallback);
};

export const updateFenStateService = (plot_id, fen_state_id, date, callback, errorCallback = undefined) => {
  let url = buildUrl(AGACAL_API_PLOT_FEN_STATE, { plot_id });
  url = buildUrlWithParameters(url, { fen_state_id, date });
  post(url, {}, { timeout: DEFAULT_HTTP_TIMEOUT }, false, callback, errorCallback);
};

export const deleteFenStateService = (plot_id, date, callback, errorCallback) => {
  const url = buildUrl(AGACAL_API_PLOT_FEN_STATE_DELETE, { plot_id, date });
  deleteRequest(url, {}, { timeout: DEFAULT_HTTP_TIMEOUT }, callback, errorCallback);
};

export const createUserService = (body, callback, errorCallback = undefined) => {
  post(AGACAL_API_USERS_PUBLIC, body, { timeout: DEFAULT_HTTP_TIMEOUT }, true, callback, errorCallback);
};
