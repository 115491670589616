import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import ChartPestsComponent from "./components/chartPestsComponent/ChartPestsComponent";
import LineWeatherComponent from "./components/lineWeatherComponent/LineWeatherComponent";
import TableRiskComponent from "./components/tableRiskComponent/TableRiskComponent";
import ParcelTitleComponent from "./components/parcelTitleComponent/ParcelTitleComponent";
import {
  getFenStateService,
  getFenStatesService,
  getPlaguesService,
  getPlotDataService,
  getPlotMeteoSummaryService,
  getPlotPlagueRisksService,
  getTreatmentsService,
  getWeatherGraphService,
  getWeatherGraphLastUpdateService,
} from "../../../../../services/commonservices";
import FilterPlagueComponent from "./components/filterPlagueComponent/FilterPlagueComponent";
import PhenologicalStateLineComponent from "./components/phenologicalStateLineComponent/phenologicalStateLineComponent";
import TreatmentLineComponent from "./components/treatmentLineComponent/TreatmentLineComponent";
import style from "./WeatherData.module.css";
import {
  COMMON__RETURN,
  PARCEL_DETAIL_METEO_LINK,
  TOOLTIP_SHOW_GRAPH,
  TOOLTIP_HIDE_GRAPH,
} from "../../../../../translations/constants";
import {
  isObjectEmpty,
  formatTimestampToDate,
  getDaysBetweenTimestamps,
  objectHasValues,
} from "../../../../../utils/utils";
import WeatherNavigationComponent from "./components/weatherNavigationComponent/WeatherNavigationComponent";
import { ROUTE_PARCELS } from "../../../../../utils/routes";
import ReturnComponent from "../../../../common/atoms/return/ReturnComponent";
import { buildUrl } from "../../../../../utils/axiosRequests";
import ButtonComponent from "../../../../common/atoms/button/ButtonComponent";
import { BUTTON } from "../../../../common/atoms/button/buttonConstants";
import LineDaysComponent from "./components/lineDaysComponent/LineDaysComponent";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import LineTreatmentDisclaimerComponent from "./components/lineTreatmentDisclaimerComponent/LineTreatmentDisclaimerComponent";
import LineDisclaimerRiskComponent from "./components/lineDisclaimerRiskComponent/LineDisclaimerRiskComponent";
import { METEO_LINK } from "./WeatherDataConstants";
import TypographyComponent from "../../../../common/atoms/typography/TypographyComponent";
import clsx from "clsx";

const WeatherDataComponent = () => {
  const intl = useIntl();

  const [filterPlagues, setFilterPlagues] = useState([]);
  const [weatherData, setWeatherData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [plotData, setPlotData] = useState({});
  const [plotPlagueRisks, setPlotPlagueRisks] = useState([]);
  const [parametersDate, setParametersDate] = useState({});
  const [parametersFilterPlagues, setParametersFilterPlagues] = useState({});
  const [positionDay, setPositionDay] = useState(0);
  const [linerWeatherData, setLinearWeatherData] = useState([]);
  const [plagues, setPlagues] = useState([]);
  const [phenologicalStateData, setPhenologicalStateData] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [fenStates, setFenStates] = useState([]);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const [gridDays, setGridDays] = useState([]);

  const { id } = useParams();

  const handleFilterPlagues = (plagues) => {
    setFilterPlagues(plagues);
    const plaguesIdsParameters = plagues.map((plague) => plague.id);
    setParametersFilterPlagues({ plagues_ids: plaguesIdsParameters });
  };

  const handlePlaguesSuccess = (response) => {
    setPlagues(response.data);
  };
  const handlePlotDataSuccess = (response) => {
    setPlotData({ ...response.data });
  };

  const handlePlotMeteoSummarySuccess = (response) => {
    setLinearWeatherData(response.data);
  };

  const handlePlotRisksSuccess = (response) => {
    setPlotPlagueRisks(response.data);
  };

  const handleWeatherData = (response) => {
    if (!response.data?.length) {
      setWeatherData([]);
      return;
    }

    let array = [...response.data];

    if (array.length === gridDays?.length) {
      setWeatherData(array);
      return;
    }

    const isDayInArray = (formattedDay, array) => {
      let formattedArray = [];
      for (const timestamp of array) {
        const arrayDate = formatTimestampToDate(timestamp.date);
        formattedArray.push(arrayDate);
      }
      return !!formattedArray.includes(formattedDay);
    };

    if (gridDays?.length > array?.length) {
      for (const day of gridDays) {
        const formattedDay = formatTimestampToDate(day);

        if (!isDayInArray(formattedDay, array)) {
          const mock = {
            date: day,
            temp_max: null,
            temp_min: null,
            prec: null,
            insolation: null,
            rel_humidity: null,
            fol_humidity: null,
          };
          array.push(mock);
        }
      }
    }

    array = array.sort((a, b) => a.date - b.date);
    setWeatherData(array);
  };

  const handleWeatherDataLastUpdate = (response) => {
    setLastUpdated(response.data?.last_updated);
  }

  const handlePhenologicalSuccess = (response) => {
    setPhenologicalStateData(response.data);
  };

  const handleTreatmentData = (response) => {
    setTreatmentData(response.data);
  };

  const navigate = useNavigate();
  const giveParametersToURL = (fromDate, toDate) => {
    navigate(`?from_date=${fromDate}&to_date=${toDate}`);
  };

  useEffect(() => {
    let elevenDaysBefore = new Date();
    let threeDaysAfter = new Date();

// Asegurarse de trabajar en UTC eliminando componentes de tiempo
    elevenDaysBefore.setUTCHours(0, 0, 0, 0);
    threeDaysAfter.setUTCHours(0, 0, 0, 0);

    const limitAfter = 3;

// Calcular las fechas en función de UTC
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const dayPosition = today.getUTCDate() + positionDay;

    elevenDaysBefore.setUTCDate(dayPosition - 10);
    threeDaysAfter.setUTCDate(dayPosition + limitAfter);

// Copias exactas para manipular de forma segura
    const copyElevenDaysBefore = new Date(elevenDaysBefore);
    const copyThreeDaysAfter = new Date(threeDaysAfter);

// Convertir a timestamps en segundos (UTC)
    const fromDate = Math.floor(copyElevenDaysBefore.getTime() / 1000); // Timestamp en segundos
    const toDate = Math.floor(copyThreeDaysAfter.getTime() / 1000); // Timestamp en segundos

    setGridDays(getDaysBetweenTimestamps(fromDate, toDate));
    threeDaysAfter = new Date(threeDaysAfter).getTime() / 1000;
    elevenDaysBefore.setHours(0, 0, 0, 0);
    elevenDaysBefore = new Date(elevenDaysBefore).getTime() / 1000;

    giveParametersToURL(elevenDaysBefore, threeDaysAfter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionDay]);

  const fenStatesCallback = (response) => {
    setFenStates(response.data);
  };

  const [searchParams] = useSearchParams();

  const getFenState = (fromDate, toDate) => {
    if (!!fromDate && !!toDate) {
      const dateParameters = {
        from_date: fromDate,
        to_date: toDate,
      };
      getFenStateService(id, { ...dateParameters }, handlePhenologicalSuccess);
    }
  };

  useEffect(() => {
    const fromDate = searchParams.get("from_date");
    const toDate = searchParams.get("to_date");
    getFenState(fromDate, toDate);
    setParametersDate({ ...parametersDate, from_date: fromDate, to_date: toDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getPlaguesService({ include_not_active: false }, handlePlaguesSuccess);
    getPlotDataService(id, handlePlotDataSuccess, true);
    getFenStatesService(fenStatesCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (objectHasValues(parametersDate)) {
      getPlotMeteoSummaryService(id, { ...parametersDate }, handlePlotMeteoSummarySuccess);
      getWeatherGraphService(id, { ...parametersDate }, handleWeatherData, false);
      getWeatherGraphLastUpdateService(id, handleWeatherDataLastUpdate, false);
      getsFilterPlaguesDependencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersDate]);

  useEffect(() => {
    if (!isObjectEmpty(parametersFilterPlagues)) {
      getsFilterPlaguesDependencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersFilterPlagues]);

  const getsFilterPlaguesDependencies = () => {
    getPlotPlagueRisksService(id, { ...parametersDate, ...parametersFilterPlagues }, handlePlotRisksSuccess);
    getTreatmentsService(id, { ...parametersDate, ...parametersFilterPlagues }, handleTreatmentData);
  };

  const getTreatmentData = () => {
    getTreatmentsService(id, { ...parametersDate, ...parametersFilterPlagues }, handleTreatmentData);
  };

  const handleChangePositionDays = (value) => {
    setPositionDay(positionDay + value);
  };

  const urlParcels = buildUrl(ROUTE_PARCELS, {});

  const [showMeteoGraphic, setShowMeteoGraphic] = useState(true);

  const toggleShowMeteoGraphic = () => {
    setDisplayTooltip(false);
    setShowMeteoGraphic(!showMeteoGraphic);
  };

  //treatment disclaimer
  const [treatmentDisclaimerDay, setTreatmentDisclaimerDay] = useState(null);
  const handleTreatmentDisclaimer = (date) => {
    setTreatmentDisclaimerDay(date);
  };
  const closeTreatmentDisclaimer = () => {
    setTreatmentDisclaimerDay(null);
  };

  useEffect(() => {
    const storedDate = localStorage.getItem("treatmentDisclaimerDay");
    if (storedDate) {
      setTreatmentDisclaimerDay(storedDate);
    }
  }, []);

  useEffect(() => {
    if (treatmentDisclaimerDay) {
      localStorage.setItem("treatmentDisclaimerDay", treatmentDisclaimerDay);
    } else {
      localStorage.removeItem("treatmentDisclaimerDay");
    }
  }, [treatmentDisclaimerDay]);

  return (
    <section className={style.container}>
      <ReturnComponent url={urlParcels} text={`<< ${intl.formatMessage({ id: COMMON__RETURN })}`} />
      <ParcelTitleComponent plotData={plotData} plotId={id} fenStates={fenStates} lastUpdated={lastUpdated}/>
      <div className={style.horizontalContainer}>
        <FilterPlagueComponent
          filterPlagues={filterPlagues}
          plagues={plagues}
          handleFilterPlagues={handleFilterPlagues}
        />

        <section className={style.buttonsContainer}>
          <WeatherNavigationComponent positionDay={positionDay} handleChangePositionDays={handleChangePositionDays} />
        </section>
      </div>

      <div className={style.chartGroup}>
        <LineDaysComponent gridDays={gridDays} />
        <LineWeatherComponent rows={linerWeatherData} gridDays={gridDays} />
        {showMeteoGraphic ? (
          <>
            <section>
              <div className={style.graphTitle}>
                <div className={style.graphTitleGroup}>
                  <div>
                    <div className={clsx(style.lineColor, style[`lineColor-red`])} />
                    <div className={clsx(style.lineColor, style[`lineColor-blue`])} />
                  </div>
                  <TypographyComponent text={"Uds"} />
                </div>
                <div className={style.graphTitleGroup}>
                  <TypographyComponent text={"%"} />
                  <div>
                    <div className={clsx(style.lineColor, style[`lineColor-grey`])} />
                    <div className={clsx(style.lineColor, style[`lineColor-yellow`])} />
                  </div>
                </div>
              </div>
            </section>

            <section className={style.graphGroup}>
              <ChartPestsComponent weatherData={weatherData} />
              <a
                href={`${METEO_LINK}${plotData.council_id}/0`}
                className={style.meteoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({ id: PARCEL_DETAIL_METEO_LINK })}
              </a>
            </section>
          </>
        ) : null}
        <div
          className={style.toggleGraphicButton}
          onMouseEnter={() => setDisplayTooltip(true)}
          onMouseLeave={() => setDisplayTooltip(false)}
        >
          <ButtonComponent
            icon={showMeteoGraphic ? <CancelPresentationOutlinedIcon /> : <InsertChartOutlinedRoundedIcon />}
            variant={BUTTON.VARIANT_ONLY_ICON}
            onClick={toggleShowMeteoGraphic}
          />
          {displayTooltip ? (
            <div className={style.tooltip}>
              {showMeteoGraphic
                ? intl.formatMessage({ id: TOOLTIP_HIDE_GRAPH })
                : intl.formatMessage({ id: TOOLTIP_SHOW_GRAPH })}
            </div>
          ) : null}
        </div>

        <TableRiskComponent
          plagues={!!filterPlagues?.length ? filterPlagues : plagues}
          plotPlagueRisks={plotPlagueRisks}
          gridDays={gridDays}
        />
        <PhenologicalStateLineComponent
          phenologicalStateData={phenologicalStateData}
          gridDays={gridDays}
          fenStates={fenStates}
          getFenState={getFenState}
        />
        <TreatmentLineComponent
          handleDisclaimer={handleTreatmentDisclaimer}
          plotData={plotData}
          treatmentData={treatmentData}
          gridDays={gridDays}
          reloadTreatments={getTreatmentData}
        />
        <LineDisclaimerRiskComponent disclaimerData={plotPlagueRisks} gridDays={gridDays} />
        {treatmentDisclaimerDay && (
          <LineTreatmentDisclaimerComponent day={treatmentDisclaimerDay} closeDisclaimer={closeTreatmentDisclaimer} />
        )}
      </div>
    </section>
  );
};

export default WeatherDataComponent;
