import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { ADD_ICON } from "../../../../../../../images/icons/addIcon";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import styles from "./treatmentStateLineComponent.module.css";
import {
  COMMON__ACTION_ACCEPT,
  DATE,
  PARCEL_FORM__INPUT__COMMENT,
  PARCEL_FORM__INPUT__DATE,
  PARCEL_FORM__INPUT__PARCELS,
  PHYTOSANITARY__PRODUCTS__SEARCH,
  PHYTOSANITARY__PRODUCTS__SEARCH_REG,
  PARCEL_FORM__INPUT__PRODUCTS,
  PARCEL_FORM__INPUT__REGISTER,
  PARCEL_FORM__INPUT_SUBLABEL__PRODUCTS,
  PARCEL_SELECT__PLAGUE_FILTER,
  PARCEL_SELECT__PLAGUE_FILTER_LABEL,
  PARCEL_TITLE__BUTTON__ADD_TREATMENT,
  TREATMENT_ABBREVIATION,
  TREATMENT_DELETE_MESSAGE,
  TREATMENT_DELETE_TITLE,
  TREATMENT_LINE__HEADER__TITLE,
  TREATMENT_SEE_MESSAGE,
  TREATMENT_SEE_TITLE,
} from "../../../../../../../translations/constants";
import { timestampToDays, timestampToDefaultDays } from "../../../../../../../utils/utils";
import { DELETE_ICON } from "../../../../../../../images/icons/deleteIcon";
import { EYE_ICON } from "../../../../../../../images/icons/eyeIcon";
import ModalComponent from "../../../../../../common/molecules/modal/ModalComponent";
import {
  deleteTreatmentService,
  getPhytosanitaryProductsService,
  getPlaguesService,
  getPlotsService,
  postCreateTreatmentService,
} from "../../../../../../../services/commonservices";
import { CREATE_TREATMENT_ERROR_FIELD, TREATMENT_SCHEMA } from "../parcelTitleComponent/parcelTitleConstants";
import { ERROR_TYPE_MIN, ERROR_TYPE_OPTIONALITY, ERROR_TYPE_REQUIRED } from "../../../../../../../utils/constants";
import { INPUT } from "../../../../../../common/atoms/input/inputConstants";
import { SELECT_FIELD_LABEL, SELECT_FIELD_VALUE } from "../../../../../../pages/parcels/components/maps/mapConstants";
import TreatmentFormComponent from "../treatmentFormComponent/treatmentFormComponent";

const SEE_TREATMENT = "seeTreatment";
const DELETE_TREATMENT = "deleteTreatment";

const TreatmentLineComponent = ({ treatmentData, gridDays, reloadTreatments, plotData, handleDisclaimer }) => {
  const intl = useIntl();
  const [treatments, setTreatments] = useState(treatmentData);
  const [displayTreatment, setDisplayTreatment] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [modalTreatmentAction, setModalTreatmentAction] = useState(SEE_TREATMENT);
  const [openModal, setOpenModal] = useState(false);
  const [errorField, setErrorField] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [disclaimerDate, setDisclaimerDate] = useState(null);

  const [plagues, setPlagues] = useState([]);
  const [parcels, setParcels] = useState([]);
  const [products, setProducts] = useState([]);

  const [tagsProducts, setTagsProducts] = useState([]);
  const [tagsParcels, setTagsParcels] = useState([]);
  const [tagsPlagues, setTagsPlagues] = useState([]);

  const [productsNameFilter, setProductsNameFilter] = useState("");
  const [productsRegNumFilter, setProductsRegNumFilter] = useState("");

  const [dateValue, setDateValue] = useState("");
  const [notesValue, setNotesValue] = useState("");

  const { formatMessage } = useCustomIntl();
  const submitButtonText = formatMessage(PARCEL_FORM__INPUT__REGISTER);

  const handlePlaguesSuccess = (response) => {
    setPlagues(response.data);
  };

  useEffect(() => {
    getPlaguesService({ summary: true }, handlePlaguesSuccess);
    getPlotsService({ summary: true }, handleParcelsSuccess);
  }, []);

  const handleOpenModal = (value) => {
    setOpenModal(value);
    setDisplayError(false);
  };
  const getPlaguesIdByProduct = (products) => {
    let uniquePlagueIds = new Set();
    products?.forEach((product) => {
      product?.plagues?.forEach((plague) => {
        uniquePlagueIds.add(+plague.id);
      });
    });
    return [...uniquePlagueIds];
  };

  const sendForm = () => {
    /* plots */
    const plots = [...tagsParcels];
    let plot_ids = [];
    plots?.forEach((item) => {
      plot_ids.push(item.id);
    });

    /* products */
    const products = [...tagsProducts];
    let product_ids = [];
    products?.forEach((item) => {
      product_ids.push(item.id);
    });

    /* plagues */
    const plague_ids = getPlaguesIdByProduct(products);

    const payload = {
      date: dateValue,
      notes: notesValue,
      product_ids: product_ids,
      plot_ids: plot_ids,
      plague_ids: plague_ids,
    };
    handleCreateTreatment(payload);
  };

  /* CREATE TREATMENT SERVICE */
  const createTreatmentCallback = () => reloadTreatments();
  const handleCreateTreatment = (body) => {
    TREATMENT_SCHEMA.validate(body)
      .then(() => {
        postCreateTreatmentService(body, createTreatmentCallback);
        setDisplayError(false);
        setErrorField("");
        setTagsProducts([]);
        setTagsParcels([]);
        setTagsPlagues([]);
        setDateValue();
        setNotesValue("");
        setOpenModal(false);
        handleDisclaimer(disclaimerDate);
      })
      .catch((error) => {
        if (
          error.type === ERROR_TYPE_REQUIRED ||
          error.type === ERROR_TYPE_MIN ||
          error.type === ERROR_TYPE_OPTIONALITY
        ) {
          setErrorField(CREATE_TREATMENT_ERROR_FIELD[error.path]);
          setDisplayError(true);
        }
      });
  };

  useEffect(() => {
    setTreatments(treatmentData);
  }, [treatmentData]);

  const objectNames = (arrayToSelectName) => arrayToSelectName?.map((item) => item.name).join(", ");

  const handleDelete = () => {
    deleteTreatmentService(selectedTreatment.id, deleteTreatmentCallback);
  };

  const changeDate = (event) => {
    const date = new Date(event).getTime() / 1000;
    setDateValue(date);
    setDisclaimerDate(date);
  };

  const changeNotes = (event) => {
    const notes = event.target.value;
    setNotesValue(notes);
  };
  const getIdPlaguesSelectedString = () => {
    if (!Array.isArray(tagsPlagues) || tagsPlagues.length === 0) {
      return "";
    }
    const idString = tagsPlagues.map((tagPlagues) => tagPlagues.id).join(",");
    return idString;
  };
  const handleSearchProducts = (value) => {
    setProductsNameFilter(value);
    if (value.length > 2 || productsRegNumFilter.length > 2) {
      const plaguesIds = getIdPlaguesSelectedString();
      let payload = {
        name: value,
        num_reg: productsRegNumFilter,
      };
      if (!!plaguesIds) payload.plague_ids = plaguesIds;
      getPhytosanitaryProductsService(payload, handleProductsSuccess);
    } else {
      setProducts([]);
    }
  };

  const handleSearchProductsByRegNum = (value) => {
    setProductsRegNumFilter(value);
    if (value.length > 2 || productsNameFilter.length > 2) {
      const plaguesIds = getIdPlaguesSelectedString();
      let payload = {
        name: productsNameFilter,
        num_reg: value,
      };
      if (!!plaguesIds) payload.plague_ids = plaguesIds;
      getPhytosanitaryProductsService(payload, handleProductsSuccess);
    } else {
      setProducts([]);
    }
  };

  const handleSearchParcels = (value) => {
    let parameters = { summary: true, name: value };
    getPlotsService(parameters, handleParcelsSuccess);
  };

  const handleParcelsSuccess = (response) => {
    setParcels(response.data);
  };

  const handleProductsSuccess = (response) => {
    setProducts(response.data);
  };

  const handleTagsProducts = (item) => {
    setTagsProducts(item);
  };

  const handleTagsParcels = (item) => {
    setTagsParcels(item);
  };

  const handleResetTagsParcels = () => {
    setTagsParcels([plotData]);
  };

  const handleTagsPlagues = (item) => {
    setTagsPlagues(item);
  };

  const getData = (tags, data) => {
    let filteredArray = [];
    if (!!data) {
      return data?.filter((item1) => {
        return !tags.some((item2) => {
          return item1?.id === item2?.id;
        });
      });
    } else return filteredArray;
  };

  const inputsStart = [
    {
      id: "1",
      name: "fecha",
      label: formatMessage(PARCEL_FORM__INPUT__DATE),
      type: INPUT.TYPE_DATE,
      value: dateValue,
      idTextPlaceholder: DATE,
      onChange: changeDate,
    },
    {
      id: "2",
      name: "notes",
      placeholder: formatMessage(PARCEL_FORM__INPUT__COMMENT),
      label: formatMessage(PARCEL_FORM__INPUT__COMMENT),
      type: INPUT.TYPE_TEXTAREA,
      value: notesValue,
      onChange: changeNotes,
    },
    {
      id: "3",
      name: "plagues",
      label: formatMessage(PARCEL_SELECT__PLAGUE_FILTER_LABEL),
      type: INPUT.TYPE_SELECT,
      variant: "secondary",
      fetching: getData,
      data: plagues,
      setTags: handleTagsPlagues,
      tags: tagsPlagues,
      fieldLabel: SELECT_FIELD_LABEL,
      fieldLValue: SELECT_FIELD_VALUE,
      initialOption: true,
      initialValue: "",
      initialText: formatMessage(PARCEL_SELECT__PLAGUE_FILTER),
    },
    {
      id: "4",
      name: "products",
      label: formatMessage(PARCEL_FORM__INPUT__PRODUCTS),
      sublabel: formatMessage(PARCEL_FORM__INPUT_SUBLABEL__PRODUCTS),
      type: INPUT.TYPE_AUTOCOMPLETE,
      variant: "secondary",
      fetching: getData,
      data: products,
      tags: tagsProducts,
      handleValues: [handleSearchProducts, handleSearchProductsByRegNum],
      setTags: handleTagsProducts,
      placeholder: [intl.formatMessage({ id: PHYTOSANITARY__PRODUCTS__SEARCH }), intl.formatMessage({ id: PHYTOSANITARY__PRODUCTS__SEARCH_REG })],
      value: "",
      idTextPlaceholder: "products",
    },
    {
      id: "5",
      name: "parcelas",
      label: formatMessage(PARCEL_FORM__INPUT__PARCELS),
      type: INPUT.TYPE_AUTOCOMPLETE,
      fetching: getData,
      handleValues: handleSearchParcels,
      data: parcels,
      tags: tagsParcels,
      setTags: handleTagsParcels,
      resetTags: handleResetTagsParcels,
      variant: "secondary",
      placeholder: "",
      value: "",
      idTextPlaceholder: "parcels",
    },
  ];

  const [inputs, setInputs] = useState(inputsStart);

  useEffect(() => {
    setInputs(inputsStart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, plagues, parcels, tagsPlagues, tagsProducts, tagsParcels]);

  const modalParameters = {
    [SEE_TREATMENT]: {
      title: `${intl.formatMessage({ id: TREATMENT_SEE_TITLE })} ${selectedTreatment?.id}`,
      bodyId: TREATMENT_SEE_MESSAGE,
      bodyParams: {
        notes: selectedTreatment?.notes,
        plagues: objectNames(selectedTreatment?.plagues),
        products: objectNames(selectedTreatment?.products),
        date: timestampToDays(selectedDay),
        highlight: (chunks) => <strong>{chunks}</strong>,
      },
      buttonAcceptText: null,
      acceptAction: () => {},
    },
    [DELETE_TREATMENT]: {
      title: `${intl.formatMessage({ id: TREATMENT_DELETE_TITLE })} ${selectedTreatment?.id}`,
      bodyId: TREATMENT_DELETE_MESSAGE,
      bodyParams: { plague: objectNames(selectedTreatment?.plagues), day: timestampToDays(selectedDay) },
      buttonAcceptText: intl.formatMessage({ id: COMMON__ACTION_ACCEPT }),
      acceptAction: handleDelete,
    },
  };

  const buildMessageToModal = () => {
    return (
      <FormattedMessage
        id={modalParameters[modalTreatmentAction].bodyId}
        values={modalParameters[modalTreatmentAction].bodyParams}
      />
    );
  };

  const handleSelectedTreatment = (plaguesObj, day, action) => {
    setSelectedDay(day);
    if (!!plaguesObj) {
      const treatment = treatmentData.find((t) => t?.id === plaguesObj?.id);
      setSelectedTreatment(treatment);
    }
    setModalTreatmentAction(action);
    setDisplayTreatment((value) => !value);
  };

  const deleteTreatmentCallback = () => reloadTreatments();

  return (
    <>
      <div className={styles.lineContainer}>
        <div className={clsx(styles.lineItemsContainer, styles.lineTitleContainer)}>
          <div className={styles.lineItemContainer}>
            <b>
              <span className={styles.treatmentsTitle}>
                {intl.formatMessage({ id: TREATMENT_LINE__HEADER__TITLE })}
              </span>
            </b>
          </div>
        </div>
        {gridDays.map((day, index) => {
          const treatmentItem = treatments.filter(
            (treatment) => timestampToDays(treatment.date) === timestampToDays(day),
          );

          return (
            <div className={styles.lineItemsContainer} key={`treatment-${day}-${index}`}>
              {!!treatmentItem.length ? (
                  <div className={styles.lineItemContainer}>
                    {treatmentItem.map(
                        (plaguesObj) =>
                            !!plaguesObj.plagues.length && (
                                <div className={styles.itemContainer} key={`icon-see-${plaguesObj.id}`}>
                                  <b className={styles.treatmentContainer}>{`${intl.formatMessage({id: TREATMENT_ABBREVIATION})}`}</b>

                                  <span
                                      className={clsx(styles.lineItemsContainer, styles.hoverIcon)}
                                      onClick={() => handleSelectedTreatment(plaguesObj, day, SEE_TREATMENT)}
                                  >
                              {EYE_ICON}
                            </span>
                                  <b className={styles.treatmentContainer}>{`${plaguesObj.id}`}</b>
                                  <span
                                      key={`icon-${plaguesObj.id}`}
                                      className={clsx(styles.lineItemsContainer, styles.deleteIcon)}
                                      onClick={() => handleSelectedTreatment(plaguesObj, day, DELETE_TREATMENT)}
                                  >
                              {DELETE_ICON}
                            </span>

                                </div>
                            ),
                    )}
                    <div
                        onClick={() => {
                          handleOpenModal(day);
                          setTagsParcels([plotData]);
                        }}
                        className={clsx(styles.lineItemContainer, styles.addIcon)}
                    >
                      {ADD_ICON}
                    </div>
                  </div>
              ) : (
                  <div
                      onClick={() => {
                        handleOpenModal(day);
                        setTagsParcels([plotData]);
                      }}
                      className={clsx(styles.lineItemContainer, styles.addIcon)}
                  >
                    {ADD_ICON}
                  </div>
              )}
              {openModal === day && (
                <ModalComponent
                  body={
                    <TreatmentFormComponent
                      day={timestampToDefaultDays(day)}
                      sendForm={sendForm}
                      inputs={inputs}
                      submitButtonText={submitButtonText}
                      displayError={displayError}
                      errorField={errorField}
                      autocompleteTagStyle="grid"
                      handleClose={handleOpenModal}
                    />
                  }
                  handleClose={handleOpenModal}
                  title={formatMessage(PARCEL_TITLE__BUTTON__ADD_TREATMENT)}
                  displayCloseButton={false}
                />
              )}
            </div>
          );
        })}
      </div>
      {!!displayTreatment && (
        <ModalComponent
          title={modalParameters[modalTreatmentAction].title}
          body={buildMessageToModal()}
          handleClose={() => setDisplayTreatment((value) => !value)}
          buttonAcceptText={modalParameters[modalTreatmentAction].buttonAcceptText}
          handleSave={() => {
            modalParameters[modalTreatmentAction].acceptAction();
            setDisplayTreatment((value) => !value);
          }}
        />
      )}
    </>
  );
};
export default TreatmentLineComponent;
