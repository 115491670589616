export const WEEK_BEFORE = "week_before";
export const WEEK_AFTER = "week_after";
export const PAGE_NEXT = "page__next";
export const PAGE_PREVIOUS = "page__previous";
export const CHART_PESTS__COLUM__LABEL__FOLIAR_WETTING = "chart_pests__colum__label__foliar_wetting";
export const CHART_PESTS__COLUM__LABEL__INSOLATION = "chart_pests__colum__label__insolation";
export const CHART_PESTS__COLUM__LABEL__MAX_TEMPERATURE = "chart_pests__colum__label__max_temperature";
export const CHART_PESTS__COLUM__LABEL__AVG_TEMPERATURE = "chart_pests__colum__label__avg_temperature";
export const CHART_PESTS__COLUM__LABEL__MIN_TEMPERATURE = "chart_pests__colum__label__min_temperature";
export const CHART_PESTS__COLUM__LABEL__PRECIPITATION = "chart_pests__colum__label__precipitation";
export const CHART_PESTS__COLUM__LABEL__RELATIVE_HUMIDITY = "chart_pests__colum__label__relative_humidity";
export const COMMON__ALL_RIGHTS_RESERVED = "common__all_rights_reserved";
export const COMMON__ERROR_401 = "common__error_401";
export const COMMON__ERROR_403 = "common__error_403";
export const COMMON__ERROR_404 = "common__error_404";
export const COMMON__ERROR_MISSING_CONTRACT_SESSION_INFO = "common__error_missing_contract_session_info";
export const COMMON__BUTTONS__CLOSE = "common__buttons__close";
export const COMMON__BUTTONS__DELETE = "common__buttons__delete";
export const COMMON__BUTTONS__MARK__READED = "common__buttons__mark__readed";
export const COMMON__BUTTONS__SAVE = "common__buttons__save";
export const COMMON__INTERNATIONALIZATIONS__INTEN = "common__internationalizations__intEn";
export const COMMON__INTERNATIONALIZATIONS__INTES = "common__internationalizations__intEs";
export const COMMON__INTERNATIONALIZATIONS__INTGL = "common__internationalizations__intGl";
export const COMMON__LEGEND = "common__legend";
export const COMMON__LANDING = "common__landing";
export const COMMON__LOADING__DESCRIPTION = "common__loading__description";
export const COMMON__LOGIN = "common__login";
export const COMMON__LOGOUT = "common__logout";
export const COMMON__VALIDATION_FIELD_ERROR_MESSAGE = "common__validation_field_error_message";
export const COMMON__VALIDATION_FIELD_ERROR_MESSAGE_NOT_VALID = "common__validation_field_error_message_not_valid";
export const COMMON__VALIDATION_FIELD_ERROR_PASSWORDS_MATCH = "common__validation_field_error_passwords_match";
export const COMMON__RETURN = "common__return";
export const COMMON__ERROR_MESSAGE_USER_ALREADY_EXISTS = "common__error_message_user_already_exists";
export const COMMON__ERROR_MESSAGE_CELLAR_ALREADY_EXISTS = "common__error_message_cellar_already_exists";
export const COMMON__ERROR_MESSAGE_PARCEL_ALREADY_EXISTS = "common__error_message_parcel_already_exists";
export const COMMON__ERROR_MESSAGE_CREATE_WARNING = "common__error_message_create_warning";
export const COMMON__LEGAL_NOTICIES = "common__legal_notices";
export const COMMON__CITIZEN_LINK = "common__citizen_link";
export const COMMON__ACCESIBILITY = "common__accesibility";
export const COMMON__PORTAL_MAP = "common__portal_map";

export const COMMON__MODAL_CLOSE = "common__modal_close";
export const COMMON__NO = "common__no";
export const COMMON__PRIVATE_PAGE = "common__private_page";
export const COMMON__PRIVACY_POLICY = "common__privacy_policy";
export const COMMON__SCREEN_HOME = "common__screen_home";
export const COMMON__TERMS_OF_USE = "common__terms_of_use";
export const COMMON__PUBLIC_PAGE = "common__public_page";
export const COMMON__ADMIN = "common__admin";
export const COMMON__WELCOME = "common__welcome";
export const COMMON__YES = "common__yes";

export const COMMON__ACTION_ACCEPT = "common__action_accept";
export const COMMON__ACTION_DELETE = "common__action_delete";
export const COMMON__ACTION_EDIT = "common__action_edit";
export const COMMON__ACTION_CLOSE = "common__action_close";

export const COMMON__CELLAR = "common__cellar";
export const COMMON__PARCEL = "common__parcel";
export const COMMON__USER = "common__user";
export const COMMON__WARNING = "common__warning";

export const ERROR_BOUNDARY__TITLE = "error_boundary__title";
export const ERROR_BOUNDARY__DESCRIPTION = "error_boundary__description";

export const LINE_DAYS_LABEL = "line_days_label";
export const LINE_WEATHER__LABEL_WEATHER = "line_weather__label_weather";
export const LINE_WEATHER__LABEL_WIND = "line_weather__label_weather_wind";

export const MENU__REGISTER = "menu__register";
export const MENU__MY_PARCELS = "menu__my_parcels";
export const MENU__MAP = "menu__map";
export const MENU__WARNIGNS = "menu__warnings";
export const MENU_PHYTOSANITARY = "menu__PHYTOSANITARY";
export const MENU__MANAGE_CELLARS = "menu__MANAGE_CELLARS";
export const MENU__MANAGE_PARCELS = "menu__MANAGE_PARCELS";
export const MENU__MANAGE_USERS = "menu__MANAGE_USERS";
export const MENU__MANAGE_WARNINGS = "menu__MANAGE_WARNINGS";
export const MENU__CONTROL_PANEL = "menu__control_panel";

export const PARCEL_TITLE__BUTTON__ADD_TREATMENT = "parcel_title__button__add_treatment";
export const PARCEL_TITLE__TABLE__HEADER__DENOMINATION = "parcel_title__table__header__denomination";
export const PARCEL_TITLE__TABLE__HEADER__PLOT_NAME = "parcel_title__table__header__plot_name";
export const PARCEL_TITLE__TABLE__HEADER__COUNCIL_NAME = "parcel_title__table__header__council_name";
export const PARCEL_TITLE__TABLE__HEADER__REGION = "parcel_title__table__header__region";
export const PARCEL_TITLE__TABLE__HEADER__TREATMENT = "parcel_title__table__header__treatment";
export const PARCEL_TITLE__TABLE__HEADER__VARIETY = "parcel_title__table__header__variety";
export const PARCEL_TITLE__TABLE__HEADER__ZONE = "parcel_title__table__header__zone";
export const FILTER_PLAGUE__BUTTON__FILTER = "parcel_title__button__add__filter_plague";
export const PARCEL_TITLE__BUTTON__MORE_INFO = "parcel_title__button__more_info";
export const PARCEL_TITLE__LAST_UPDATE = "parcel_title__last_update";
export const PARCEL_SELECT__PLAGUE_FILTER = "parcel_select__plague_filter";
export const PARCEL__PLAGUE_FILTER= "parcel__plague_filter";

export const PARCEL_SELECT__PLAGUE_FILTER_LABEL = "parcel_select__plague_filter_label";

export const PARCEL_SELECT__PLAGUE_TREATMENT = "parcel_select__plague_treatment";

export const PARCEL_FORM__INPUT__DATE = "parcel_form__input__date";
export const PARCEL_FORM__INPUT__PRODUCTS = "parcel_form__input__products";
export const PARCEL_FORM__INPUT_SUBLABEL__PRODUCTS = "parcel_form__input_sublabel__products";
export const PARCEL_FORM__INPUT__PRODUCT_NAME = "parcel_form__input__product_name";
export const PARCEL_FORM__INPUT__PRODUCT_REG_NUM = "parcel_form__input__product_reg_num";
export const PARCEL_FORM__INPUT__PARCELS = "parcel_form__input__parcels";
export const PARCEL_FORM__INPUT__DISEASE = "parcel_form__input__disease";
export const PARCEL_FORM__INPUT__COMMENT = "parcel_form__input__comment";
export const PARCEL_FORM__INPUT__REGISTER = "parcel_form__button__register";
export const PARCEL_FORM__INPUT__NAME = "parcel_form__input__name";
export const PARCEL_FORM__INPUT__DESCRIPTION = "parcel_form__input__description";
export const PARCEL_FORM__PARCEL_CREATED_MODAL_TITLE = "parcel_form__parcel_created_modal_title";
export const PARCEL_FORM__PARCEL_CREATED_MODAL_TEXT = "parcel_form__parcel_created_modal_text";

export const PARCELS_LIST__FILTER_TITLES = "parcels_list__filter_title";
export const PARCELS_LIST__LEVEL_RISKS = "parcels_list__level_risks";
export const PARCELS_LIST__MAP_LEGEND_TITLE = "parcels_list__map_legend_title";
export const PARCELS_LIST__MAP_LEGEND_TEXT1_PRIVATE = "parcels_list__map_legend_text1_private";
export const PARCELS_LIST__MAP_LEGEND_TEXT1_PUBLIC = "parcels_list__map_legend_text1_public";
export const PARCELS_LIST__MAP_LEGEND_TEXT2 = "parcels_list__map_legend_text2";
export const PARCELS_LIST__MAP_LEGEND_SUBTITLE = "parcels_list__map_legend_subtitle";
export const ZONES_LIST__MAP_LEGEND_SUBTITLE = "zones_list__map_legend_subtitle";
export const PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_VIGILANCE = "parcels_list__map_legend_risk_level_vigilance";
export const PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_LOW = "parcels_list__map_legend_risk_level_low";
export const PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_MEDIUM = "parcels_list__map_legend_risk_level_medium";
export const PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_HIGH = "parcels_list__map_legend_risk_level_high";
export const PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_NONE = "parcels_list__map_legend_risk_level_none";
export const PARCELS_LIST__MAP_PLAGUES_FILTER = "parcels_list__map_plagues_filter";
export const PARCELS_LIST__MAP_DAYS_FILTER = "parcels_list__map_days_filter";
export const PARCELS_LIST__MAP_DAYS_MODAL_TITLE = "parcels_list__map_days_modal_title";
export const PARCELS_LIST__MAP_DAYS_MODAL_TEXT = "parcels_list__map_days_modal_text";
export const PARCELS_LIST__CELLARS_FILTER = "parcels_list__cellars_filter";
export const PARCELS_LIST__FILTER_BY_GRAPES = "parcels_list__filter_by_grapes";
export const PARCELS_LIST__FILTER_DEFAULT_OPTION_GRAPE_VARIETY = "parcels_list__filter_default_option_grape_variety";
export const PARCELS_LIST__FILTER_DEFAULT_OPTION_ZONE = "parcels_list__filter_default_option_zone";

export const PARCELS_LIST__OPEN_MAP_LEGEND = "parcels_list__open_map_legend";
export const PARCELS_LIST__CLOSE_MAP_LEGEND = "parcels_list__close_map_legend";
export const PARCELS_LIST__TITLE = "parcels_list__title";
export const PARCELS_LIST__PARCEL_ID = "parcels_list__parcel_id";
export const PARCELS_LIST__PARCEL_WEIGHT = "parcels_list__parcel_weight";
export const PARCELS_LIST__PARCEL_DESTINATION = "parcels_list__parcel_destination";
export const PARCELS_LIST__PARCEL_SEARCH_PLACEHOLDER = "parcels_list__parcel_search_placeholder";
export const PARCELS_LIST__PARCELS_BY_RISKS = "parcels_list__parcels_by_risks";
export const PARCELS_LIST__PARCELS = "parcels_list__parcels";
export const PARCELS_SINGULAR_PARCEL = "parcel_singular_parcel";

export const ZONES_LIST__ZONES = "zones_list__zones";
export const ZONES_SINGULAR_ZONE = "zones_singular_zone";

export const PARCELS_MANAGEMENT__TITLE = "parcels_management_title";
export const PARCELS_MANAGEMENT__CREATE = "parcels_management_create";

export const CELLARS_MANAGEMENT__TITLE = "cellars_management_title";

export const CREATE_PARCEL_FORM__TITLE = "create_parcel_form__title";
export const CREATE_USER_FORM__TITLE = "create_user_form__title";
export const CREATE_CELLAR_FORM__TITLE = "create_cellar_form__title";
export const CREATE_WARNING_FORM__TITLE = "create_warning_form__title";

export const EDIT_PARCEL_FORM__TITLE = "edit_parcel_form__title";
export const EDIT_USER_FORM__TITLE = "edit_user_form__title";
export const EDIT_CELLAR_FORM__TITLE = "edit_cellar_form__title";
export const EDIT_WARNING_FORM__TITLE = "edit_warning_form__title";

export const USERS_MANAGEMENT__TITLE = "users_management_title";

export const PHENEOLOGICAL_STATE_LINE__HEADER__TITLE = "pheneological_state_line__header__title";
export const PHENOLOGICAL_STATE__CHANGE_ERROR = "phenological_state__change_error";
export const PHENOLOGICAL_STATE__DELETE_MODAL_TITLE = "phenological_state__delete_modal_title";
export const PHENOLOGICAL_STATE__DELETE_MODAL_TEXT = "phenological_state__delete_modal_text";
export const PHENOLOGICAL_STATE__DELETE_ERROR_MESSAGE = "phenological_state__delete_error_message";
export const PHENOLOGICAL_STATE__SET_ERROR_MESSAGE = "phenological_state__set_error_message";

export const WARNINGS__TITLE = "warnings__title";
export const WARNINGS__NO__DATA = "warnings__no__data";
export const WARNINGS__SORT__BY__DATE__ASC = "warnings__sort__by__date__asc";
export const WARNINGS__SORT__BY__DATE__DES = "warnings__sort__by__date__des";
export const WARNINGS__MODAL__SUBTITLE = "warnings__modal__subtitle";
export const WARNINGS__RISK_LEVEL_NONE = "warnings__risk_level_none";
export const WARNINGS__RISK_LEVEL_VIGILANCE = "warnings__risk_level_vigilance";
export const WARNINGS__RISK_LEVEL_LOW = "warnings__risk_level_low";
export const WARNINGS__RISK_LEVEL_MEDIUM = "warnings__risk_level_medium";
export const WARNINGS__RISK_LEVEL_HIGH = "warnings__risk_level_high";

export const MAP__RISK_LEVEL_NONE = "map__risk_level_none";
export const MAP__RISK_LEVEL_VIGILANCE = "map__risk_level_vigilance";
export const MAP__RISK_LEVEL_LOW = "map__risk_level_low";
export const MAP__RISK_LEVEL_MEDIUM = "map__risk_level_medium";
export const MAP__RISK_LEVEL_HIGH = "map__risk_level_high";

export const PHYTOSANITARY__PRODUCTS__TITLE = "phytosanitary__products__title";
export const PHYTOSANITARY__PRODUCTS__NUM__REG = "phytosanitary__products__num_reg";
export const PHYTOSANITARY__PRODUCTS__OWNER = "phytosanitary__products__owner";
export const PHYTOSANITARY__PRODUCTS__FORM = "phytosanitary__products__form";
export const PHYTOSANITARY__PRODUCTS__PLAGUES = "phytosanitary__products__plagues";
export const PHYTOSANITARY__PRODUCTS__SEARCH = "phytosanitary__products__search";
export const PHYTOSANITARY__PRODUCTS__SEARCH_REG = "phytosanitary__products__search_reg";
export const PHYTOSANITARY__PRODUCTS__DATA__SHEET = "phytosanitary__products__data__sheet";
export const PHYTOSANITARY__PRODUCTS__NO__DATA = "phytosanitary__products__no__data";
export const PHYTOSANITARY__FILTER__PLAGUES = "phytosanitary__filter__plagues";

export const TABLE_OBSERVATION__LABEL__OBSERVATION = "table_observation__label__observation";

export const TREATMENT_LINE__HEADER__TITLE = "treatment_line__header__title";

export const SUNNY = "SUNNY";
export const PARTLY_CLOUDY = "PARTLY_CLOUDY";
export const HIGH_CLOUDS = "HIGH_CLOUDS";
export const OVERCAST = "OVERCAST";
export const CLOUDY = "CLOUDY";
export const FOG = "FOG";
export const SHOWERS = "SHOWERS";
export const OVERCAST_AND_SHOWERS = "OVERCAST_AND_SHOWERS";
export const INTERMITENT_SNOW = "INTERMITENT_SNOW";
export const DRIZZLE = "DRIZZLE";
export const RAIN = "RAIN";
export const SNOW = "SNOW";
export const STORMS = "STORMS";
export const MIST = "MIST";
export const MID_CLOUDS = "MID_CLOUDS";
export const WEAK_RAIN = "WEAK_RAIN";
export const WEAK_SHOWERS = "WEAK_SHOWERS";
export const STORM_THEN_CLOUDY = "STORM_THEN_CLOUDY";
export const MELTED_SNOW = "MELTED_SNOW";
export const RAIN_HAIL = "RAIN_HAIL";
export const FOG_BANK = "FOG_BANK";

export const METEO__STATE = "meteo__state";
export const COLUMNS__COLOR = "columns__color";
export const LINES__COLOR = "lines__color";
export const METEO__ICONS = "meteo__icons";
export const COMMON__RISK = "common__risk";
export const COMMON__PROTECTION = "common__protection";
export const LEGEND__PRECIPITATION = "legend__precipitation";
export const LEGEND__FOLIAR__WETTING = "legend__foliar__wetting";
export const LEGEND__MIN__TEMP = "legend__min__temp";
export const LEGEND__AVG__TEMP = "legend__avg__temp";
export const LEGEND__MAX__TEMP = "legend__max__temp";
export const LEGEND__RELATIVE__HUMIDITY = "legend__relative__humidity";
export const LEGEND__INSOLATION = "legend__insolation";

export const DATE = "date";

export const CREATE_PARCEL_FORM__SECTION_INFO = "create_parcel_form__section_info";
export const CREATE_PARCEL_FORM__SECTION_PLANTATION = "create_parcel_form__section_plantation";
export const CREATE_PARCEL_FORM__SECTION_LOCATION = "create_parcel_form__section_location";
export const CREATE_PARCEL_FORM__SECTION_GEOGRAPHY = "create_parcel_form__section_geography";
export const CREATE_PARCEL_FORM__SECTION_GEOPOSITION = "create_parcel_form__section_geoposition";
export const CREATE_PARCEL_FORM__SECTION_CADASTRAL_REFERENCE = "create_parcel_form__section_cadastral_reference";

export const COMMON__INPUT__NAME = "common__input__name";
export const COMMON__INPUT__CODE = "common__input__code";
export const COMMON__INPUT__DESCRIPTION = "common__input__description";
export const COMMON__INPUT__USER_NAME = "common__input__user_name";
export const COMMON__INPUT__ROL = "common__input__rol";
export const COMMON__INPUT__EMAIL = "common__input__email";
export const COMMON__INPUT__EMAIL_DESCRIPTION = "common__input__email_description";
export const COMMON__SELECT_PLACEHOLDER = "common__select_placeholder";

export const CREATE_PARCEL_FORM__INPUT__CELLAR = "create_parcel_form__input__cellar";
export const CREATE_PARCEL_FORM__INPUT__DESCRIPTION = "create_parcel_form__input__description";
export const CREATE_PARCEL_FORM__INPUT__VARIETY = "create_parcel_form__input__variety";
export const CREATE_PARCEL_FORM__INPUT__CONDUCTION = "create_parcel_form__input__conduction";
export const CREATE_PARCEL_FORM__INPUT__MAINTENANCE = "create_parcel_form__input__maintenance";
export const CREATE_PARCEL_FORM__INPUT__DENSITY = "create_parcel_form__input__density";
export const CREATE_PARCEL_FORM__INPUT__PLANTATION_YEAR = "create_parcel_form__input__plantation_year";
export const CREATE_PARCEL_FORM__INPUT__IRRIGATION = "create_parcel_form__input__irrigation";
export const CREATE_PARCEL_FORM__INPUT__ZONE = "create_parcel_form__input__zone";
export const CREATE_PARCEL_FORM__INPUT__LOCATION = "create_parcel_form__input__location";
export const CREATE_PARCEL_FORM__INPUT__SURFACE = "create_parcel_form__input__surface";
export const CREATE_PARCEL_FORM__INPUT__ORIENTATION = "create_parcel_form__input__orientation";
export const CREATE_PARCEL_FORM__INPUT__PROVINCE = "create_parcel_form__input__province";
export const CREATE_PARCEL_FORM__INPUT__COUNCIL = "create_parcel_form__input__council";
export const CREATE_PARCEL_FORM__INPUT__WEATHER_STATION = "create_parcel_form__input__weather_station";
export const CREATE_PARCEL_FORM__INPUT__LATITUDE = "create_parcel_form__input__latitude";
export const CREATE_PARCEL_FORM__INPUT__LONGITUDE = "create_parcel_form__input__longitude";
export const CREATE_PARCEL_FORM__INPUT__ALTITUDE = "create_parcel_form__input__altitude";
export const CREATE_PARCEL_FORM__INPUT__REFERENCE = "create_parcel_form__input__reference";
export const CREATE_PARCEL_FORM__TEXT = "create_parcel_form__text";

export const CREATE_USER_FORM__INPUT__USERNAME = "create_user_form__input__username";
export const CREATE_USER_FORM__INPUT__EMAIL = "create_user_form__input__email";
export const CREATE_USER_FORM__INPUT__ROLE = "create_user_form__input__role";
export const CREATE_USER_FORM__INPUT__PASSWORD = "create_user_form__input__password";
export const CREATE_USER_FORM__INPUT__PASSWORD_CONFIRM = "create_user_form__input__password_confirm";

export const CREATE_USER_FORM__USER_INFO_SECTION = "create_user_form__user_info_section";
export const CREATE_USER_FORM__INPUT__PARCEL_SELECTION = "create_user_form__input__parcel_selection";
export const CREATE_USER_FORM__INPUT__CELLAR_SELECTION = "create_user_form__input__cellar_selection";

export const MAP_MODAL_PLAGUE_NAME = "map_modal_plague_name";
export const MAP_MODAL_RISK_LEVEL = "map_modal_risk_level";

export const MAP_DAY_TODAY = "map_day_today";
export const MAP_DAY_TOMORROW = "map_day_tomorrow";
export const MAP_DAY_AFTER_TOMORROW = "map_day_after_tomorrow";

export const WEEKDAY_MONDAY = "weekday_monday";
export const WEEKDAY_TUESDAY = "weekday_tuesday";
export const WEEKDAY_WEDNESDAY = "weekday_wednesday";
export const WEEKDAY_THURSDAY = "weekday_thursday";
export const WEEKDAY_FRIDAY = "weekday_friday";
export const WEEKDAY_SATURDAY = "weekday_saturday";
export const WEEKDAY_SUNDAY = "weekday_sunday";

export const MONTH_JANUARY = "month_january";
export const MONTH_FEBRUARY = "month_february";
export const MONTH_MARCH = "month_march";
export const MONTH_APRIL = "month_april";
export const MONTH_MAY = "month_may";
export const MONTH_JUNE = "month_june";
export const MONTH_JULY = "month_july";
export const MONTH_AUGUST = "month_august";
export const MONTH_SEPTEMBER = "month_september";
export const MONTH_OCTOBER = "month_october";
export const MONTH_NOVEMBER = "month_november";
export const MONTH_DECEMBER = "month_december";

export const CREATE_CELLAR_FORM__INPUT__NAME = "create_cellar_form__input__name";
export const CREATE_CELLAR_FORM__INPUT__CODE = "create_cellar_form__input__code";

export const COMMON__DELETE_MODAL_TITLE = "common__delete_modal_title";
export const COMMON__DELETE_MODAL_TEXT = "common__delete_modal_text";

export const CREATE_TREATMENT_FORM__INPUT__DATE = "create_treatment_form__input__date";
export const CREATE_TREATMENT_FORM__INPUT__NOTES = "create_treatment_form__input__notes";
export const CREATE_TREATMENT_FORM__INPUT__PLOT__IDS = "create_treatment_form__input__plot__ids";
export const CREATE_TREATMENT_FORM__INPUT__PLAGUE__IDS = "create_treatment_form__input__plague__ids";
export const CREATE_TREATMENT_FORM__INPUT__PRODUCT__IDS = "create_treatment_form__input__product__ids";
export const CREATE_TREATMENT_FORM__INPUT__ADD__ALL__PARCELS = "create_treatment_form__input__add__all__parcels"
export const CREATE_TREATMENT_FORM__INPUT__REMOVE__ALL__PARCELS = "create_treatment_form__input__remove__all__parcels"

export const MODAL_DETAIL = "modal_detail";
export const MODAL_OBSERVATION_TITLE = "modal_observation_title";
export const MODAL_OBSERVATION_TEXT_ADD = "modal_observation_text_add";
export const MODAL_OBSERVATION_TEXT_DELETE = "modal_observation_text_delete";
export const TOOLTIP_RISK_LEVEL = "tooltip_risk_level";
export const TOOLTIP_PROTECTION_LEVEL = "tooltip_protection_level";
export const TOOLTIP_OBSERVATION = "tooltip_observation";
export const TOOLTIP_SHOW_GRAPH = "tooltip_show_graph";
export const TOOLTIP_HIDE_GRAPH = "tooltip_hide_graph";

export const COMMON__HIGH = "common__high";
export const COMMON__MEDIUM = "common__medium";
export const COMMON__LOW = "common__low";
export const COMMON__RISK_NONE = "common__risk_none";
export const COMMON__PROTECTION_NONE = "common__protection_none";

export const TREATMENT_DELETE_TITLE = "treatment_delete_title";
export const TREATMENT_SEE_TITLE = "treatment_see_title";

export const TREATMENT_ABBREVIATION = "treatment_abbreviation";

export const TREATMENT_DELETE_MESSAGE = "treatment_delete_message";
export const TREATMENT_SEE_MESSAGE = "treatment_see_message";
export const PROTECTION_HIGH = "protection_high";
export const PROTECTION_MEDIUM = "protection_medium";
export const PROTECTION_LOW = "protection_low";

export const OBSERVATION_TRUE = "observation_true";
export const OBSERVATION_FALSE = "observation_false";

export const WARNINGS_MANAGEMENT__TITLE = "warnings_management_title";
export const CREATE_WARNING_FORM_INPUT__PLOT_ID = "create_warning_form_input__plot_id";
export const CREATE_WARNING_FORM_INPUT__NAME = "create_warning_form_input__name";
export const CREATE_WARNING_FORM_INPUT__DESCRIPTION = "create_warning_form_input__description";
export const CREATE_WARNING_FORM_INPUT__LEVEL = "create_warning_form_input__level";

export const COMMON_DAY = "common_day";
export const TITLE_WARNINGS_PARCEL = "title_warnings_parcel";
export const TITLE_WARNINGS_NAME = "title_warning_name";
export const COMMON_RISK_LEVEL = "common_risk_level";
export const TITLE_WARNING_MESSAGE = "title_warning_message";

export const DISCLAIMER_TREATMENT_MESSAGE = "disclaimer_treatment_message";
export const DISCLAIMER_MESSAGE_ONE_DAY = "disclaimer_message_one_day";
export const DISCLAIMER_MESSAGE_FEW_DAYS = "disclaimer_message_few_days";
export const DISCLAIMER_MESSAGE_FEW_DAYS_CONSECUTIVE = "disclaimer_message_few_days_consecutive";

export const PARCEL_DETAIL_METEO_LINK = "parcel_detail_meteo_link";

export const WIND_UNIT = "m/s";

export const REGISTER__TITLE = "register__title";
export const REGISTER__FORM__USERNAME_REQUIRED = "register__error_username_required";
export const REGISTER__FORM__USERNAME_VALID = "register__error_username_valid";
export const REGISTER__FORM__EMAIL_REQUIRED = "register__error_mail_required";
export const REGISTER__FORM__EMAIL_VALID = "register__error_mail_valid";
export const REGISTER__FORM__PASSWORD_REQUIRED = "register__error_password_required";
export const REGISTER__FORM__PASSWORD_MIN = "register__error_password_min";
export const REGISTER__FORM__PASSWORD_MAX = "register__error_password_max";
export const REGISTER__FORM__PASSWORD_VALID = "register__error_password_valid";
export const REGISTER__FORM__PHONE_REQUIRED = "register__error_phone_required";
export const REGISTER__FORM__PHONE_VALID = "register__error_phone_valid";
export const REGISTER__DATA_PROTECTION = "register__data_protection";

export const HEADER__TITLE = "header__title"
export const FOOTER__COPYRIGHT_TEXT = "footer__copyright_text";