import React, { useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import styles from "./lineDays.module.css";
import { LINE_DAYS_LABEL } from "../../../../../../../translations/constants";
import { timestampToDaysShort, timestampToDaysShorter } from "../../../../../../../utils/utils";

const LineDaysComponent = ({ gridDays }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const divRef = useRef(null);

  const intl = useIntl();
  let today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  let todayTimestamp = Date.parse(today) / 1000;
  let minDateTextWidth = 68;

  useEffect(() => {
    const handleResize = () => {
      const container = divRef.current;
      if (container) {
        setIsOverflowing(container.clientWidth <= minDateTextWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          if (divRef.current) {
            handleResize();
            break;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [minDateTextWidth]);

  return (
    <div>
      <div className={styles.lineContainer}>
        <div className={styles.lineItemsContainer}>
          <span>{intl.formatMessage({ id: LINE_DAYS_LABEL })}</span>
        </div>
        {gridDays.map((day, index) => {
          let dayAtMidnight = new Date(day * 1000);
          dayAtMidnight.setUTCHours(0, 0, 0, 0);
          let dayAtMidnightTimestamp = Date.parse(dayAtMidnight) / 1000;
          return (
            <div
              className={`${styles.lineItemsContainer} ${dayAtMidnightTimestamp === todayTimestamp && styles.highlightedItem}`}
              key={index + "row"}
              ref={divRef}
            >
              {isOverflowing ? timestampToDaysShorter(day) : timestampToDaysShort(day)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LineDaysComponent;
